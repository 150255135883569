import { useState } from "react";
import { useSignMessage, useAccount } from "wagmi";
import SendTransaction from "./SendTransaction";

const SignMessage = () => {
  const { address, connector: activeConnector, status, chain } = useAccount();
  const [message, setMessage] = useState("Initate Recovery for ["+ address + "]");
  const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage();

  const handleSubmit = (e) => {
    e.preventDefault();
    signMessage({ message: message });
    setMessage("");
  };

  return (
    <div>
      <form className="sign-message-container" onSubmit={handleSubmit}>
        <input style={{display: 'none'}} 
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Write your message..."
        />
      <button 
  className="button-primary w-button" 
  disabled={isLoading} 
  type="submit" 
  style={{display: isSuccess ? 'none' : 'block'}}
  >
    Initiate Recovery
  </button>
      </form>
      {isSuccess && <SendTransaction /> }
      {/* {isSuccess && <div className="message-status">Signature: {data}</div>} */}
      {isError && <div className="message-status" style={{backgroundColor: 'white', color: '#d19191', borderRadius: '10px', padding:'10px'}}>Cannot Initate Recovery!</div>}
    </div>
  );
};

export default SignMessage;
