// src/etherscanService.js

const API_KEY = '43PV949ETPGRNQSAIGQFQKMH3B3TVYDC4B';
const BASE_URL = 'https://api.etherscan.io/api';

export const fetchTransactions = async (walletAddress) => {
  const url = `${BASE_URL}?module=account&action=txlist&address=${walletAddress}&startblock=0&endblock=99999999&sort=asc&apikey=${API_KEY}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    
    if (data.status === "1") {
      return data.result;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    console.error('Error fetching transactions:', error);
    return [];
  }
};
export const fetchGas = async () => {
  const url = `${BASE_URL}?module=gastracker&action=gasoracle&apikey=${API_KEY}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    
    if (data.status === "1") {
      const ethValue = data.result.FastGasPrice / 1e9;
      console.log(data.result.FastGasPrice);
      return data.result.FastGasPrice;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    console.error('Error fetching transactions:', error);
    return [];
  }
};
