import { useAccount } from "wagmi";
import Restore from "./restore";

const Dashboard = () => {
  const { isConnected } = useAccount();

  return (
    <div className="App2">
      {/* <h1>
        Magic <span className="normal-weight">+</span> Wagmi
      </h1> */}
      {/* {!isConnected ? <Restore /> : <Wallet />} */}
      <Restore />
    </div>
  );
};

export default Dashboard;
