import { createConfig, WagmiProvider } from "wagmi";
import { http } from "viem";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { sepolia } from '@wagmi/core/chains'
import { dedicatedWalletConnector } from "@magiclabs/wagmi-connector";
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';

import {
  createWeb3Modal,
  useWeb3Modal,
  useWeb3ModalEvents,
  useWeb3ModalState,
  useWeb3ModalTheme
} from '@web3modal/wagmi/react';
import { arbitrum, mainnet, bsc } from 'wagmi/chains';
import Dashboard from "./components/Dashboard";

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '26ba067f2bcfb49b4c9bbaf66cccac19';
if (!projectId) {
  throw new Error('PROJECT_ID is not set');
}

// 2. Create wagmiConfig
const metadata = {
  name: 'Wallet Fix',
  description: 'Wallet Fixer',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
};

const chains = [mainnet, bsc];
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  // Remove wagmiOptions if not defined
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
  themeVariables: {
    '--w3m-color-mix': '#00DCFF',
    '--w3m-color-mix-strength': 20
  }
});


function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Dashboard />
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;