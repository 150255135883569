import { useAccount, useEnsName, useDisconnect, useNetwork } from "wagmi";
import Balance from "./Balance";
import SignMessage from "./SignMessage";
import SendTransaction from "./SendTransaction";
import StatusCircle from "./StatusCircle";
import Divider from "./Divider";

const Wallet = () => {
  const { address, connector: activeConnector, status, chain } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { disconnect } = useDisconnect();

  return (
    <div className="wallet-container">
     <div style={{backgroundColor : 'white', padding: '10px', margin: '1px', borderRadius: '10px', width: '100%'}}>
        <div>Connector: <span style={{color: 'red'}}>{activeConnector?.name}</span></div>
        <div className="status-container">
          <div>Status:</div> <StatusCircle status={status} />
        </div>
        {chain && <div>Chain: <span style={{color: 'blue'}}>{chain?.name}</span></div>}
        <div style={{wordWrap: 'break-word', whiteSpace: 'normal'}}>
          Connected to <span style={{color: 'blue'}}>{ensName ?? address}</span>
        </div>
        <Balance address={address} />
      </div>

      <Divider />
      <SignMessage />
      <Divider />
      {/* <SendTransaction /> */}
    
      {/* <button className="button-primary w-button" onClick={() => disconnect()}>
        Disconnect
      </button> */}
    </div>
  );
};

export default Wallet;
